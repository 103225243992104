
import './App.css';
import axios from "axios";
import { useEffect, useState } from "react";



function App() {

  const [listOfPosts, setListOfPosts] = useState([]);

  useEffect(() => {

    // Client send GET to the server, and get the response
    //
    //axios.get("http://localhost:3001/posts").then((response) => {
    axios.get("https://fstacksrv.arcanetlink.com/posts").then((response) => {

      // The response.data is an array of JS objects taken from the 
      // database. Each object corresponds to row of the table
      //
      setListOfPosts(response.data);
        //console.log(response.data);

    });

  }, []); //useEffect()




  return (
      <div className="App">

        {listOfPosts.map((value, key) => {
        
            return (
              <div className="post">
                <div className="title"> {value.title} </div>
                <div className="body">{value.postText}</div>
                <div className="footer">{value.username}</div>
              </div>
            );
        })}

    </div>
  );
}

export default App;
